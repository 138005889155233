.tool-tip-container {
  position: relative;
  display: inline-block;
}

.tool-tip-wrapper {
  top: 0;
  display: inline-block;
  background-color: white;
  max-width: 500px;
  position: absolute;
  box-shadow: 1px 4px 5px rgb(197, 197, 197);
  max-height: 500px;
  overflow: auto;
  z-index: 100;
}

.tool--left {
  left: -15px;
}

.tool--right {
  right: 15px;
}

.tool-tip-pointer-right {
  position: absolute;
  top: -20px;
  left: calc(100% - 26px);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.tool-tip-pointer-left {
  position: absolute;
  top: -20px;
  left: 26px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
