$primary: #477aa4;
$success: #10c273;
$white: #ffffff;
$redAlert: #ec5555;
$drop-shadow: rgb(177, 177, 177);

$gray-light: #969eac;
$white-smoke: #f6f6f6;
$gray: #999;
$purple: #534cea;
$primary-blue: #1e4795;
$light-sky-blue: #f1f2ff;
$light-background: #fbf8ff;

$transparent: transparent;

$headerBackground: #ececed;

$faded-text: #b9b9b9;

$bright-blue: #1992fc;
