// Custom
@import '_variables.scss';

@import 'icon.scss';
@import 'task.scss';
@import 'error.scss';
@import 'tooltip.scss';

main {
  background: #f8faff;
  display: flex !important;
}

.pointer {
  cursor: pointer;
}
