.icon-btn {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  background-color: $primary;
  border: 1px solid transparent;
  color: white;
}

.btn-outlined {
  background-color: white;
  border: 1px solid rgb(200, 200, 200);
  color: blue;
}

.secondary {
  background-color: white;
  color: black;
}

.icon-btn-label {
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 4px;
}

.raised {
  box-shadow: 1px 1px 3px #888888;
}

.icon-btn-disabled {
  opacity: 0.5;
  cursor: default;
}
