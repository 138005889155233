$button-primary: #2cc5d2;

.add-task {
  width: 30rem;
  height: 12rem;
  margin: auto;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
}

.add-task input {
  display: block;
  margin: auto;
  margin-bottom: 0.5rem;
  width: 20rem;
  padding: 0.5rem;
  border: 1px solid #333;
  border-radius: 0.3rem;
}

.add-task button {
  width: 20rem;
  padding: 0.7rem;
  border: 1px solid #333;
  background: $button-primary;
  color: #eee;
  border-radius: 0.3rem;
}
